import React, {Component} from "react";
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";
import "../../css/forms.css";
import Error from "./Error";

export default @observer class InputObserver extends Component {
    type = "text";
    name = "";
    placeholder = "";

    constructor(props) {
        super(props);
        this.type = props.type;
        this.name = props.name;
        this.placeholder = props.placeholder;
        this.errorMessage = props.errorMessage;
    }

    onBlur = (event) => {
        const name = event.target.name;
        formInfoStorage.touch(name);
    };

    render() {
        let inputClassName = "form__text_input ";
        if(formInfoStorage.errors[this.name] && formInfoStorage.errors[this.name].status === true) {
            inputClassName += "form__text_input_error";
        }

        return (
            <React.Fragment>
                <input
                    style={this.props.height ? {height: this.props.height} : {} }
                    type={this.type}
                    name={this.name}
                    defaultValue={formInfoStorage.info.values[this.name]}
                    onChange={(event) => formInfoStorage.setInfo(this.name, event.target.value)}
                    onBlur={this.onBlur}
                    placeholder={this.placeholder}
                    className={inputClassName}
                />
                <Error name={this.name}/>
            </React.Fragment>
        )
    }
}