import React from "react";
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";
import "../../css/stepper.css";

export default @observer class StepperWidget extends React.Component {
    max_step = formInfoStorage.max_step;

    finishedIcon = (
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3.81812L4 6.81812L10 0.818116" stroke="#2678CB" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    render() {
        const step = formInfoStorage.current_step;


        //not cool, but... just let it be, man. Nothing is perfect...
        let steps = [];
        for (let i = 1; i <= this.max_step-1; i++) {
            steps.push("");
        }

        //i've made it work once and i'm afraid if i break it if i start refactoring
        return (
                <div className={"stepper__container"}>
                    <ul className={"stepper__steps"}>
                        {
                            steps.map((key, index) => {
                                return (
                                    <div className={"stepper__step_container "
                                            + ( (index+1 < step)
                                                ? "stepper__step_container_finished"
                                                : (index+1 === step)
                                                    ? "stepper__step_container_current"
                                                    : "stepper__step_container_disabled"
                                    )}>
                                        <li key={index}
                                            className={"stepper__step " +
                                            ( ((index+1) > step)
                                            ? "stepper__step_disabled"
                                            : "" )}

                                            onClick={() => {
                                                formInfoStorage.goTo(index+1)
                                            }}
                                        >

                                            {(index+1) < step
                                            ? this.finishedIcon
                                            : (index+1) === step
                                                ? (index+1)
                                                : ""
                                            }

                                        </li>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            )

    }
}