import {action, computed, observable, toJS} from "mobx";
import ContactInfoForm from "./components/ContactInfoForm";
import MovingType from "./components/MovingType";
import Summary from "./components/Summary";
import InventoryStep from "./components/InventoryStep";
import AdditionalInformation from "./components/AdditionalInformation";
import ReferenceNumberPage from "./components/ReferenceNumberPage";

class FormInfoStorage {
        @observable form_info = {
            values: {
                name: "",
                phone: "",
                how_to_contact: "",
                email: "",

                moving_type: "home_removal",

                when_type: null,
                when: null,

                from: null,
                to: null,

                from_property_type: null,
                from_property_type_detail: null,
                to_property_type: null,
                to_property_type_detail: null,

                from_floor: null,
                to_floor: null,

                from_lift_available: null,
                to_lift_available: null,

                from_walking_distance: null,
                to_walking_distance: null,

                dismantling: false,
                dismantling_details: "",
                have_keys: true,
                special_requirements: "",

            },
            touched: {},
            errors: {
                name: {
                    status: false,
                    message: ""
                },
                phone: {
                    status: false,
                    message: "",
                },
                email: {
                    status: false,
                    message: "",
                },
                how_to_contact: {
                    status: false,
                    message: "",
                },

                when_type: {
                    status: false,
                    message: "",
                },
                when: {
                    status: false,
                    message: "",
                },

                from: {
                    status: false,
                    message: "",
                },
                to: {
                    status: false,
                    message: "",
                },

                from_property_type: {
                    status: false,
                    message: "",
                },
                from_property_type_detail: {
                    status: false,
                    message: "",
                },
                to_property_type: {
                    status: false,
                    message: "",
                },
                to_property_type_detail: {
                    status: false,
                    message: "",
                },

                from_lift_available: {
                    status: false,
                    message: "",
                },
                to_lift_available: {
                    status: false,
                    message: "",
                },

                from_floor: {
                    status: false,
                    message: "",
                },
                to_floor: {
                    status: false,
                    message: "",
                },

                from_walking_distance: {
                    status: false,
                    message: "",
                },
                to_walking_distance: {
                    status: false,
                    message: "",
                },
            },
            currentStep: 1,
        };

        @observable steps = {
            1: ContactInfoForm,
            2: MovingType,
            3: InventoryStep,
            4: AdditionalInformation,
            5: Summary,
            6: ReferenceNumberPage,
        };

        @observable inventory = {
        };

        @observable ref_number = null;

        //need to check these fields to decide if next steps are allowed to go to
        @observable step_fields = {
            1: ["how_to_contact", "phone", "email", "name"],
            2: ["moving_type", "when_type" ,"when", "from", "to",
                "from_property_type", "from_property_type_detail", "to_property_type", "to_property_type_detail",
                "from_floor", "to_floor", "from_lift_available", "to_lift_available",
                "from_walking_distance", "to_walking_distance"],
            3: [],
            4: ["special_requirements"],
            5: [],
        };

        @observable required = ["name", "phone", "email", "how_to_contact",
            "when_type", "when",
            "from", "to",
            "from_property_type", "from_property_type_detail", "to_property_type", "to_property_type_detail",
            "from_floor", "to_floor"];

        constructor() {
            this.prepopulateInventory();
        }

        @computed get max_step() {
            return Object.keys(toJS(this.steps)).length;
        };
        @computed get name() {
            return this.form_info.values.name;
        }
        @computed get phone() {
            return this.form_info.values.phone;
        }
        @computed get howToContact() {
            return this.form_info.values.how_to_contact;
        }
        @computed get email() {
            return this.form_info.values.email;
        }
        @computed get current_step() {
            return this.form_info.currentStep;
        }
        @computed get info() {
            return this.form_info;
        }
        @computed get errors() {
            return this.form_info.errors;
        }
        @computed get values() {
            return this.form_info.values;
        }


        //checks all the required fields that are in current step and check if they are not null or empty
        @computed get isNextStepAllowed() {
            let values = this.form_info.values;

            const currentStep = this.current_step;

            const fields = this.step_fields[currentStep];
            if(fields) {
                let required_fields = fields.filter( field => {
                    return this.required.includes(field);
                } );

                for (const field of required_fields) {
                    //this field may contain invalid value and it will not be shown
                    if(field === "to_property_type_detail") {
                        if(values.moving_type !== "office_removal" &&
                            (values["to_property_type_detail"] === "house" || values["to_property_type_detail"] === "flat")) {
                            return false;
                        }
                    }
                    if(field === "from_property_type_detail") {
                        if(values.moving_type !== "office_removal"
                            && (values["from_property_type_detail"] === "house" || values["from_property_type_detail"] === "flat")) {
                            return false;
                        }
                    }

                    if (values[field] === "" || values[field] === null) {
                        return false;
                    }
                }
            }

            //if it's inventory step - check if there any items
            if(currentStep === 3) {
                const inventory = toJS(this.inventory);

                const lists = Object.keys(inventory);
                let hasItems = this.hasItems();
                if(!hasItems) {
                    return false;
                }
            }

            return true;
        }

        @computed get propertyTypeOptionsTo() {
            let options = {};

            console.log("loading options to...");

            const propertyType = this.form_info.values.to_property_type;
            // eslint-disable-next-line default-case
            switch (propertyType) {
                case "flat":
                    options = {
                        "1 bed": "1 bed flat",
                        "2 bed": "2 bed flat",
                        "3 bed": "3 bed flat",
                        "4+ bed": "4+ bed flat",
                    };

                    break;

                case "house":
                    options = {
                        "1 bed": "1 bed house",
                        "2 bed": "2 bed house",
                        "3 bed": "3 bed house",
                        "4+ bed": "4+ bed house",
                    };
                    break;

                case "other": {
                    options = {
                        "studio": "Studio",
                        "storage": "Storage unit",
                        "flatshare": "Flatshare",
                        "other": "Other",
                    };
                    if(this.values.moving_type === "office_removal") {
                        options = {
                            "studio": "Studio",
                            "storage": "Storage unit",
                            "flatshare": "Flatshare",
                            "other": "Other",
                            "flat": "Flat",
                            "house": "House",
                        };
                    }

                    break;
                }
            }

            return options;
        }
        @computed get propertyTypeOptionsFrom() {
            let options = {};


            const propertyType = this.form_info.values.from_property_type;


            console.log("loading options from..." + propertyType);
            // eslint-disable-next-line default-case
            switch (propertyType) {
                case "flat":
                    options = {
                        "1 bed": "1 bed flat",
                        "2 bed": "2 bed flat",
                        "3 bed": "3 bed flat",
                        "4+ bed": "4+ bed flat",
                    };

                    break;

                case "house":
                    options = {
                        "1 bed": "1 bed house",
                        "2 bed": "2 bed house",
                        "3 bed": "3 bed house",
                        "4+ bed": "4+ bed house",
                    };
                    break;

                case "other": {
                    options = {
                        "studio": "Studio",
                        "storage": "Storage unit",
                        "flatshare": "Flatshare",
                        "other": "Other",
                    };
                    if(this.values.moving_type === "office_removal") {
                        options = {
                            "studio": "Studio",
                            "storage": "Storage unit",
                            "flatshare": "Flatshare",
                            "other": "Other",
                            "flat": "Flat",
                            "house": "House",
                        };
                    }

                    break;
                }
            }

            return options;
        }

        @action nextStep = () => {
            console.log("NEXT STEP");
            //validate
            let validated = this.validateStep(this.current_step);
            //go to next step
            if(validated === true) {
                if(toJS(this.form_info.currentStep) !== this.max_step) {
                    this.form_info.currentStep++;
                    console.log("CURRENT STEP: " + toJS(this.form_info.currentStep));
                }
            }
        };

        @action prevStep = () => {
            this.form_info.currentStep--;
        };

        @action goTo = (step) => {
            const current_step = this.current_step;

            //freely move backwards
            if(step <= current_step) {
                this.form_info.currentStep = step;
            }
            else {
                //validate
                for (let validating_step = current_step; validating_step < step; validating_step++) {
                    //move to first unvalidated step
                    if( !this.validateStep(validating_step) ) {

                        this.setStep(validating_step);
                        return;
                    }
                }

                this.setStep(step);
            }
        };

        @action setStep(step) {
            this.form_info.currentStep = step;
        }

        //note for refactoring: it's possible to have these rules registered somewhere instead of hard coding it there
        @action setInfo(name, value) {
            this.setError(name, false, "");

            //if user chooses preferred way to contact - we allow to go to next step by making only chosen way required
            if(name === "how_to_contact") {
                if(value === "phone") {
                    if(this.required.indexOf("phone") === -1) {
                        this.required.push("phone");
                    }
                    this.required.splice(this.required.indexOf("email"), 1);
                }
                if(value === "email") {
                    if(this.required.indexOf("email") === -1) {
                        this.required.push("email");
                    }
                    this.required.splice(this.required.indexOf("phone"), 1);
                }
            }

            //delete irrelevant info if user changes property type
            if(name === "to_property_type" || name === "from_property_type") {
                if(this.form_info.values[name] !== value) {
                    this.setInfo(name+"_detail", null);
                }
            }

            //if "office building" set remove second select from required
            if(name === "to_property_type" || name === "from_property_type") {
                if(value === "office_building") {
                    this.required.splice(this.required.indexOf(name + "_detail"), 1);
                }
                if(formInfoStorage.values[name] === "office_building" && value !== "office_building") {
                    this.required.push(name + "_detail");
                }
            }

            //adjust required fields on 2 step according to chosen moving type
            if(name === "moving_type") {
                console.log("adjusting required fields for moving type");
                if(value === "house_clearance" || value === "furniture_disposal" || value === "man_power") {
                    //there is no final destination for "house clearance" and "furniture disposal" so i remove fields for final destination
                    this.required = this.required.filter(field => {
                        console.log(field.substr(0,2) !== "to");
                        return field.substr(0,2) !== "to"
                    });
                }
                else {
                    //check if there are required fields for final destination and add them if needed
                    const fields = Object.keys(toJS(this.values));
                    fields.map(field => {
                        if(field.substr(0,2) === "to") {
                            //push if not in array
                            if(this.required.indexOf(field) === -1) {
                                if(field !== "to_lift_available" && field !== "to_walking_distance")
                                this.required.push(field);

                                console.log("Pushed required field: " + field);
                            }
                        }
                        return field;
                    })
                }
            }

            //clear inventory if switching to "moving_type" = office removal
            if(name === "moving_type") {
                if(
                    (value === "office_removal" && this.form_info.values.moving_type !== "office_removal")
                    || (value !== "office_removal" && this.form_info.values.moving_type === "office_removal")
                ) {
                    this.values["moving_type"] = value;
                    this.prepopulateInventory();
                }

            }

            //if invalid value for property type becomes valid - reset error
            if(name === "moving_type") {
                if(this.values["to_property_type_detail"]) {
                   this.setError("to_property_type_detail", false, "");
                }
                if(this.values["from_property_type_detail"]) {
                    this.setError("from_property_type_detail", false, "");
                }
            }

            //clear property type when switching from and to office removal
            if(name === "moving_type") {
                if(this.values.moving_type === "office_removal" && value !== "office_removal") {
                    this.values.to_property_type = null;
                    this.values.from_property_type = null;
                    this.values.to_property_type_detail = null;
                    this.values.from_property_type_detail = null;

                    if(this.required.indexOf("to_property_type_detail") === -1) {this.required.push("to_property_type_detail")}
                    if(this.required.indexOf("from_property_type_detail") === -1) {this.required.push("from_property_type_detail")}
                }
                if(this.values.moving_type !== "office_removal" && value === "office_removal") {
                    this.values.to_property_type = null;
                    this.values.from_property_type = null;
                    this.values.to_property_type_detail = null;
                    this.values.from_property_type_detail = null;

                    if(this.required.indexOf("to_property_type_detail") === -1) {this.required.push("to_property_type_detail")}
                    if(this.required.indexOf("from_property_type_detail") === -1) {this.required.push("from_property_type_detail")}
                }
            }

            //when "when_type" selected adjust required fields
            if(name === "when_type") {
                const fields = ["fixed_date", "between_dates"];
                if(fields.includes(value)) {
                    if(this.required.indexOf(name) !== -1) {
                        this.required.push("when");
                    }
                }
                else {
                    this.required = this.required.filter(field => field !== "when");
                }
            }


            this.form_info.values[name] = value;
        }

        @action setError(name, status, message) {
            this.form_info.errors[name] = {
                status: status,
                message: status ? message : "",
            }

        }

        //~~~ inventory utility ~~~
        @action deleteItem(list, name) {
            if(this.inventory[list]) {
                console.log("deleting: " + name);
                console.log("from: " + list);

                this.inventory[list] = this.inventory[list].filter(item => (item.name !== name));
            }
        }

        @action newItem(list) {
            this.inventory[list].push({
                name: "",
                count: 1,
            });
        }

        @action stack(list, name) {

            let items = this.inventory[list];
            let length = items.length;

            let first_found = null;

            function count(item) {
                if(item.count) {
                    return parseInt(item.count, 10);
                }
                else {
                    return 0;
                }
            }

            for (let i = 0; i < length ; i++) {
                if(items[i].name === name) {
                    if(first_found !== null) {
                        //after editing of count it may convert to String so we need to ensure that it's integer
                        items[first_found].count = count(items[first_found]) + count(items[i]) ;
                        items.splice(i, 1);
                        i--;
                        length = items.length;
                    }
                    else {
                        first_found = i;
                    }
                }
            }
        }

        @action setItemName(list, item, value) {
            this.inventory[list] = this.inventory[list].filter(initial_item => {
               if(initial_item.name === item.name) {
                   initial_item.name = value;
               }

               return true;
            });
        }

        hasItems() {
            const inventory = toJS(this.inventory);

            const lists = Object.keys(inventory);
            let hasItems = false;
            for (let list of lists) {
                for(let item of inventory[list]) {
                    if(item.count) {
                        return true;
                    }
                }
            }
            return hasItems;
        }
        //!~~ inventory utility ~~!

        @action touch(name) {
            this.form_info.touched[name] = true;
            //validate
            console.log("validating on touch: " + name);

            if(this.validators[name]) {
                this.validators[name]();
            }
        }

        @action prepopulateInventory() {
            const movingType = this.values.moving_type;

            if(movingType !== "office_removal") {
                this.inventory = {
                    living: [
                        {name: "Two Seater Sofa", count: null},
                        {name: "Three Seater Sofa", count: null},
                        {name: "Armchair", count: null},
                        {name: "Coffee Table", count: null},
                        {name: "Small TV (Less Than 30\")", count: null},
                        {name: "Large TV (Larger Than 40\")", count: null},
                        {name: "TV Stand", count: null},
                        {name: "Bookcase", count: null},
                        {name: "Rug", count: null},
                        {name: "Desk", count: null},
                        {name: "Office Chair", count: null},
                        {name: "Artwork", count: null},
                    ],
                    kitchen: [
                        {name: "Fridge Freezer", count: null},
                        {name: "Washing Machine", count: null},
                        {name: "Microwave Oven", count: null},
                        {name: "Dishwasher", count: null},
                        {name: "Kitchen Table", count: null},
                        {name: "Dining Chair", count: null},
                        {name: "Bin", count: null},
                        {name: "Cooker", count: null},
                    ],
                    bathroom: [
                        {name: "Large Mirror", count: null},
                        {name: "Small Mirror", count: null},
                        {name: "Rug", count: null},
                        {name: "Bathroom Cabinet", count: null},
                        {name: "Bath Tub", count: null},
                    ],
                    boxes: [
                        {name: "Large Box", count: null},
                        {name: "Medium Box", count: null},
                        {name: "Small Box", count: null},
                        {name: "Wardrobe Box", count: null},
                        {name: "Suitcase", count: null},
                        {name: "Bag", count: null},
                    ],
                    bedrooms: [
                        {name: "Double Bed & Mattress", count: null},
                        {name: "Kingsize Bed & Mattress", count: null},
                        {name: "Single Wardrobe", count: null},
                        {name: "Chest Of Drawers", count: null},
                        {name: "Bedside Table", count: null},
                        {name: "Dressing Table", count: null},
                        {name: "Television", count: null},
                        {name: "Double Wardrobe", count: null},
                        {name: "Single Bed & Mattress", count: null},
                    ],
                    // garden: [
                    //     {name: "Garden Table", count: null},
                    //     {name: "Garden Chairs", count: null},
                    //     {name: "Small Plant Pot", count: null},
                    //     {name: "Large Plant Pot", count: null},
                    //     {name: "Lawn Mower", count: null},
                    //     {name: "Bike", count: null},
                    // ]

                };
            }
            else {
                this.inventory = {
                    office: [
                        {name: "Desk", count: null},
                        {name: "Chair", count: null},
                        {name: "Pedestal", count: null},
                        {name: "Filing cabinet", count: null},
                        {name: "Desktop computer", count: null},
                        {name: "Photocopier", count: null},
                        {name: "Printer", count: null},
                        {name: "Board room table", count: null},
                        {name: "Boxes - large", count: null},
                        {name: "Boxes - medium", count: null},
                    ]
                };
            }
        }
        // <---------------------------------- Validations --------------------------------------->

        validateStep(step) {
            let validated = true;

            if(toJS(this.step_fields)[step]) {
                const fields = toJS(this.step_fields)[step];

                //run validations for fields
                for (const field of fields) {

                    if(this.validators[field]) {
                        validated = this.validators[field]() && validated;
                    }
                }

                //run validations for step (any rules specific to a step)
                if(this.validators["step_" + step]) {
                    validated = this.validators["step_" + step]() && validated;
                }
            }

            return validated;
        }

        checkIfEmpty(field, error) {
            let validated = true;

            if(this.required.includes(field)) {
                //check if empty
                if(this.values[field] === null || this.values[field] === "") {
                    this.setError(field, true, error);
                    validated = false;
                }
            }

            return validated;
        }


        validateName = () => {
            let validated = true;

            if(this.required.includes("name")) {
                //check if empty
                if(this.name === "") {
                    this.setError("name", true, "This field is required");
                    validated = false;
                }
            }
            //no further validations so far

            return validated;
        };
        validatePhone = () => {
            let validated = true;

            if(this.required.includes("phone")) {
                //check if empty
                if(this.phone === "") {
                    this.setError("phone", true, "This field is required");
                    validated = false;
                }
            }
            //check if at least 11 symbols
            if(this.phone !== "" && this.phone.match(/\d/g) && this.phone.match(/\d/g).length < 11 ) {
                this.setError("phone", true, "Phone should contain at least 11 digits");
                validated = false;
            }

            //check if phone valid
            let re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            let valid = re.test(this.values.phone);
            if(this.values.phone !== "" && !valid) {
                this.setError("phone", true, "This phone number is not valid");
                validated = false;
            }

            return validated;
        };
        validateEmail = () => {
            let validated = true;

            if(this.required.includes("email")) {
                //check if empty
                if(this.email === "") {
                    this.setError("email", true, "This field is required");
                    validated = false;
                }
            }

            //check if valid
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let valid = re.test(this.values.email);
            if(this.values.email !== "" && !valid) {
                this.setError("email", true, "This email is not valid");
                validated = false;
            }

            return validated;
        };
        validateHowToContact = () => {
            let validated = true;

            if(this.required.includes("how_to_contact")) {
                //check if empty
                if(this.howToContact === "") {
                    this.setError("how_to_contact", true, "This field is required");
                    validated = false;
                }
            }
            return validated;
        };

        validatePropertyType = (prefix) => {
            let validated = true;

            validated = validated && this.checkIfEmpty(prefix + "_property_type", "This field is required");

            if(formInfoStorage.values[prefix + "_property_type"] === "office_building"
                && formInfoStorage.values.moving_type !== "office_removal") {

                validated = false;
                this.setError(prefix + "_property_type", true, "This field is required");
            }

            if(formInfoStorage.values.moving_type === "office_removal"
                && formInfoStorage.values[prefix + "_property_type"] !== "other"
                && formInfoStorage.values[prefix + "_property_type"] !== "office_building") {
                validated = false;

                this.setError(prefix + "_property_type", true, "This field is required");
            }

            return validated;
        };

        validatePropertyTypeDetail = (prefix) => {
            let validated = true;

            validated = validated && this.checkIfEmpty(prefix + "_property_type_detail", "This field is required");

            if( (formInfoStorage.values[prefix + "_property_type_detail"] === "flat"
                || formInfoStorage.values[prefix + "_property_type_detail"] === "house")
                && formInfoStorage.values.moving_type !== "office_removal" ) {
                validated = false;
                this.setError(prefix + "_property_type_detail", true, "This field is required");
            } else { this.setError(prefix + "_property_type_detail", false, ""); }

            return validated;
        };

        validators = {
            name: this.validateName,
            phone: this.validatePhone,
            email: this.validateEmail,
            how_to_contact: this.validateHowToContact,

            when_type: () => this.checkIfEmpty("when_type", "This field is required"),
            when: () => this.checkIfEmpty("when", "This field is required"),

            from: () => this.checkIfEmpty("from", "This field is required"),
            to: () => this.checkIfEmpty("to", "This field is required"),

            from_property_type: () => this.validatePropertyType("from"),
            from_property_type_detail: () => this.validatePropertyTypeDetail("from"),
            to_property_type: () => this.validatePropertyType("to"),
            to_property_type_detail: () => this.validatePropertyTypeDetail("to"),

            from_floor: () => this.checkIfEmpty("from_floor", "This field is required"),
            to_floor: () => this.checkIfEmpty("to_floor", "This field is required"),

            from_lift_available: () => this.checkIfEmpty("from_lift_available", "This field is required"),
            to_lift_available: () => this.checkIfEmpty("to_lift_available", "This field is required"),

            from_walking_distance: () => this.checkIfEmpty("from_walking_distance", "This field is required"),
            to_walking_distance: () => this.checkIfEmpty("to_walking_distance", "This field is required"),

            moving_type: () => () => this.checkIfEmpty("moving_type", "This field is required"),

            step_1: () => true,
            step_2: () => true,
            step_3: () => {
                return this.hasItems();
            }
        };
    }

    const formInfoStorage = new FormInfoStorage();
    export default formInfoStorage;