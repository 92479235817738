import React from "react";
import formInfoStorage from "../FormInfoStorage";
import {observer} from "mobx-react";

import "../css/steps/referencenumberstep.css";

export default @observer class ReferenceNumberPage extends React.Component {

    render() {

        return (
            <div className={"reference__container"}>
                <h1>Thank you!</h1>
                <p>We'll get back to you with a quote, usually within an hour during office hours.</p>

                <small>Your reference number is:</small>
                <span className={"reference__number"}>{formInfoStorage.ref_number ? formInfoStorage.ref_number : "No reference number"}</span>
            </div>
        );
    }
}