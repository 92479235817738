import React, {Component} from "react";
import FormInfoStorage from '../FormInfoStorage';
import {observer} from "mobx-react";
import InputObserver from "./Form/InputObserver";

import "../css/global.css";
import "../css/forms.css";
import SelectObserver from "./Form/SelectObserver";

export default @observer class ContactInfoForm extends Component {

    render() {
        return (
            <div>
                <h1 className={"h1"}>Your contact details</h1>
                <form style={{marginTop: '38px'}}>
                    <div className={"form__group"}>
                        <label className={"form__label"}>Name</label>
                        <InputObserver type={"text"} name={"name"}
                                       value={FormInfoStorage.name}
                                       placeholder={"Enter your name"}
                                       errorMessage={"Enter your name"}
                        />
                    </div>
                    <div className={"form__group"}>
                        <label className={"form__label"}>Phone</label>
                        <InputObserver type={"tel"} name={"phone"}
                                       value={FormInfoStorage.phone}
                                       placeholder={"Enter your phone number"}
                                       errorMessage={"Enter your phone number"}
                        />
                    </div>
                    <div className={"form__group"}>
                        <label className={"form__label"}>Email</label>
                        <InputObserver type={"email"} name={"email"}
                                       value={FormInfoStorage.email}
                                       placeholder={"Enter your email"}
                                       errorMessage={"Enter your email"}
                        />
                    </div>
                    <div className={"form__group"}>
                        <label className={"form__label"}>How would you like us to contact you?</label>
                        <SelectObserver
                            options={{
                                phone: "Phone",
                                email: "Email",
                            }}
                            name={"how_to_contact"}
                        />
                    </div>
                </form>
            </div>


        )
    }
}