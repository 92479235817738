import React from "react";
import formInfoStorage from "../../FormInfoStorage";

import "../../css/buttons.css";


export default class BackButton extends React.Component {

    render() {
        return (
            <button onClick={formInfoStorage.prevStep} className={"button button__back"}>
                Back
            </button>
        );
    }
}