import React from 'react';
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";
import ItemToolbar from "./ItemToolbar";

export default @observer class ListItem extends React.Component {

    constructor(props) {
        super(props);

        let editing = false;
        if(!this.props.item.name) {
            editing = true;
        }
        if(props.editing) {
            editing = props.editing;
        }

        this.state = {
            editing: editing
        };

    }

    change = (value) => {
        this.props.item.name = value;
    };

    edit = () => {

        this.setState(prevState => ({
            ...prevState,
            editing: true
        }))
    };

    deleteItem = () => {
        formInfoStorage.deleteItem(this.props.list, this.props.item.name);
    };

    exitEditMode = (e) => {
        if(this.state.editing) {
            let exit = true;

            let parent = e.target;
            if(parent && parent.className === "inventory-list__item_name") {
                exit = false;
            }
            while(parent && parent.className !== "inventory-list__item_name") {
                parent = parent.parentNode;

                if(parent && parent.className === "inventory-list__item_name") {
                    exit = false;
                }
            }

            if(exit) {
                this.setState(prevState => ({
                    ...prevState,
                    editing: false,
                }));

                //delete it count = 0 and click is outside
                if(this.props.item.count === 0 && (e.target.className !== "inventory-list__item")) {
                    this.deleteItem();
                }
            }

            //stack all the items with the same name in the list
            formInfoStorage.stack(this.props.list, this.props.item.name);

            //delete if value is empty and it's not a click on a toolbar
            const clickOnToolbar = false;
            if( !this.props.item.name && !clickOnToolbar) {
                this.deleteItem();
            }
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.exitEditMode);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.exitEditMode);
    }

    saveAndNew = (e) => {
        let key = e.key;

        if(key && key === "Enter") {
            // this.exitEditMode(e);


            formInfoStorage.newItem(this.props.list);
        }
    };



    render() {
        const item = this.props.item;
        const list = this.props.list;


        return (
            <div className={"inventory-list__item"}>
                {
                    this.state.editing ?
                        (
                            <div className={"inventory-list__item_name"}>
                                <input style={{backgroundColor: 'transparent'}} value={item.name} onChange={(e) => {this.change(e.target.value)}} placeholder={"Add title..."}
                                       onKeyPress={this.saveAndNew} autoFocus={true}/>
                            </div>
                        )
                        : (
                            <div className={"inventory-list__item_name"}>
                                <p style={{userSelect: 'none'}}>{item.count !== null ? (<strong>({item.count}x) </strong>) : "" } {item.name}</p>
                            </div>
                        )
                }
                <ItemToolbar openIcon={this.props.openIcon} item={item} list={list} onEdit={this.edit}/>
            </div>
        );
    }
}