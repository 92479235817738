import React from "react";
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";
import {toJS} from "mobx";
import ListItem from "./ListItem";

import "../../css/pen_icon.css";

const OpenIcon = () => (
    <svg className={"pen_icon"} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5182 0.431804C10.9425 -0.143935 10.009 -0.143935 9.43327 0.431804L1.2826 8.58245L1.18142 8.68364L1.15037 8.82334L0.167522 13.2462L0 14L0.75385 13.8325L5.17667 12.8496L5.31637 12.8186L5.41756 12.7174L13.5682 4.56675C14.144 3.99101 14.144 3.05755 13.5682 2.48181L11.5182 0.431804ZM10.1283 1.12678C10.3202 0.934869 10.6313 0.934869 10.8232 1.12678L12.8732 3.17679C13.0652 3.36871 13.0652 3.67986 12.8732 3.87177L12.1958 4.54925L9.45077 1.80426L10.1283 1.12678ZM8.75579 2.49924L2.07877 9.17624L1.29449 12.7055L4.82377 11.9212L11.5008 5.24423L8.75579 2.49924Z" fill="#0A0E12"/>
    </svg>
);

export default @observer class SummaryList extends React.Component {

    render() {
        //construct array of items to display
        const lists = Object.keys(toJS(formInfoStorage.inventory));
        let items = [];
        // if(lists) {
        //     for (const list of lists) {
        //         const list_items = formInfoStorage.inventory[list];
        //
        //         for(const item of list_items) {
        //             if(item.count) {
        //                 items.push({item: item, list: list});
        //             }
        //         }
        //     }
        // }

        items = this.props.items;

        return (
            <div className={"inventory-list"}>
                {this.props.header ? this.props.header : null}
                {items ? items.map(item => {
                    return (
                        <ListItem item={item.item} list={item.list}/>
                    )
                }) : null}
                <div className={"inventory-list__bottom"} />
            </div>
        );
    }
}