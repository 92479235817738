import React, {Component} from "react";
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";
import Radio from "./Radio";

import "../../css/movingtype.css";
import "../../css/radio.css"



export default @observer class MovingTypeSelect extends Component {

    render() {
        const checked = formInfoStorage.values.moving_type;

        return (
            <div className={"radio"}>
                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"single_item"} text={"Single item"} />
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"few_items"} text={"A few items"}/>
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"home_removal"} text={"Home Removal"}/>
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"office_removal"} text={"Office Removal"}/>
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"furniture_disposal"} text={"Furniture Disposal"}/>
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"house_clearance"} text={"House Clearance"}/>
                </div>

                <div style={{marginRight:'15px'}} className={"radio__item"}>
                    <Radio name={"moving_type"} value={"man_power"} text={"Man power only"}/>
                </div>
            </div>
        );
    }
}