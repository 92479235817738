import React from "react";
import {observer} from "mobx-react";

import formInfoStorage from "../FormInfoStorage";

import "../css/steps/additionalinfostep.css";
import "../css/dismantle-textbox.css";
import Checkbox from "./Form/Checkbox";
import {Textarea} from "./Form/Textbox/Textarea";


const ToolsIcon = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="19.1973" y="20.4907" width="2" height="26.5081" transform="rotate(-45 19.1973 20.4907)" fill="#C4C4C4"/>
        <rect x="46.2676" y="10.501" width="2" height="36.6812" transform="rotate(45 46.2676 10.501)" fill="#C4C4C4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.0493 20.0516C23.5979 16.503 23.5979 10.7496 20.0493 7.20093C17.0859 4.2375 12.5848 3.74881 9.11429 5.73485L15.653 12.2736L12.2712 15.6553L5.73252 9.11662C3.74647 12.5872 4.23517 17.0882 7.1986 20.0516C10.7472 23.6003 16.5007 23.6003 20.0493 20.0516Z" fill="#C4C4C4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.4038 38.4053C34.8552 41.9539 34.8552 47.7073 38.4038 51.256C41.3673 54.2194 45.8683 54.7081 49.3388 52.722L42.8001 46.1833L46.1819 42.8015L52.7206 49.3403C54.7067 45.8697 54.218 41.3687 51.2545 38.4052C47.7059 34.8566 41.9524 34.8566 38.4038 38.4053ZM51.2568 51.2537L51.2522 51.2583C51.253 51.2575 51.2538 51.2567 51.2545 51.256C51.2553 51.2552 51.2561 51.2544 51.2568 51.2537Z" fill="#C4C4C4"/>
        <path d="M51.624 3.62554L54.3232 6.32475L48.9113 13.0863L44.8625 9.03748L51.624 3.62554Z" fill="#312A2A"/>
        <rect x="16.8828" y="36.5576" width="6.30115" height="18.7908" transform="rotate(45 16.8828 36.5576)" fill="#262626"/>
    </svg>
);

export default @observer class AdditionalInformation extends React.Component {

    render() {
        const placeholder = "E.g. Sofas won’t fit in the lift, washing machine needs disconnection," +
            " double yellow lines outside the property. The more information, the better!";

        const dismantlingRequired = formInfoStorage.values.dismantling;
        return (
            <div>
                <h1 className={"additional-info__header"} style={{marginBottom: '50px'}}>
                    Additional information
                </h1>

                <div className={"dismantle"}>
                    <div style={{width: '100%'}}>
                        <Checkbox name={"dismantling"} label={"Dismantling and reassembly service required"} labelClass={"additional-info__dismantle-label"}/>
                        <div style={{width: '100%'}}>
                            {
                                dismantlingRequired ?
                                    (
                                        <React.Fragment >
                                            <p className={"dismantle__text"}>Please provide details of any items that will need dismantling and reassembling</p>
                                            <Textarea className={"dismantle-textbox"} name={"dismantling_details"} placeholder={"Type some text here..."}/>
                                        </React.Fragment>
                                      ) : null
                            }
                        </div>

                    </div>
                </div>
                <hr style={{marginTop:'48px', marginBottom: '40px'}}/>

                {
                    formInfoStorage.values.moving_type === "home_removal" ?
                        (
                            <div className={"additional-info__keys"}>
                                <h2>Getting into the new property, will you...</h2>

                                <div style={{marginBottom:'10px'}}>
                                    <Checkbox name={"have_keys"} label={"Already have the keys to the new property"}/>
                                </div>
                                <div>
                                    <Checkbox inverted={true} name={"have_keys"} label={"Picking up the keys on completion of the sale"}/>
                                </div>
                                <hr style={{marginTop:'40px', marginBottom: '40px'}}/>
                            </div>
                        ) : null
                }


                <div className={"special-requirements"}>
                    <p>Any special requirements or notes?</p>
                    <Textarea placeholder={placeholder} name={"special_requirements"}/>
                </div>
            </div>
        )
    }
}