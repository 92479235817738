import React from 'react';
import DateRangePicker from "react-daterange-picker";
import {observer} from "mobx-react";

import formInfoStorage from "../../FormInfoStorage";
import '../../css/date-picker/date-picker.css';
import '../../css/date-picker/date-select.css';
import '../../css/select.css';
import Select, {components} from "react-select";
import Error from "./Error";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M1.28033 0C1.42098 0 1.55587 0.0558739 1.65533 0.15533L4 2.5L6.34467 0.15533C6.44413 0.0558737 6.57902 0 6.71967 0C7.19214 0 7.42876 0.571241 7.09467 0.90533L4 4L0.905329 0.905329C0.57124 0.57124 0.807856 0 1.28033 0Z" fill="#0A0E12"/>
                </svg>
            </components.DropdownIndicator>
        )
    );
};


export default @observer class DatePicker extends React.Component {
    name = this.props.name;
    type = this.props.type;

    PaginationArrow = (props) => {
        const direction = props.direction;

        const containerClassName = "DateRangePicker__PaginationArrow " +
            (direction === "next" ? "DateRangePicker__PaginationArrow--next" : "DateRangePicker__PaginationArrow--previous");
        const iconClassName = "DateRangePicker__PaginationArrowIcon " +
            (direction === "next" ? "DateRangePicker__PaginationArrowIcon--next" : "DateRangePicker__PaginationArrowIcon--previous");


        return (
            <div {...props} className={containerClassName} onClick={props.onTrigger}>
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClassName}>
                    <path d="M1.9 5L6.2 0.7L5.5 0L0 5.5L5.5 11L6.2 10.3L1.9 6H12L12 5H1.9Z" fill="#0A0E12"/>
                </svg>
            </div>
        )
    };

    onSelect = (dates) => {
        formInfoStorage.setInfo(this.name, dates);

    };

     Menu = ({innerProps, innerRef}) => {
         let numberOfCalendars = 1;
         if(this.type === "range") {
             numberOfCalendars = 2;
         }

         const date = formInfoStorage.values[this.name];

         //check if type was switched so invalid value don't break widget
         let value = null;
         if(date)
         {
             if(this.type === "single" && Object.keys(date).length > 2)
             {
                 value = date;
             }
             else if(this.type === "range" && Object.keys(date).length === 2) {
                 value = date;
             }
             else {
                 value = null;
             }
         }

         return (
            <div {...innerProps} className={"picker-container"}>
                <DateRangePicker
                    onSelect={this.onSelect}
                    value={value}
                    selectionType={this.type}

                    numberOfCalendars={numberOfCalendars}

                    paginationArrowComponent={this.PaginationArrow}
                />
            </div>
         )
     };


    render() {

        const date = formInfoStorage.values[this.name];

        let value = null;
        if(date)
        {
            console.log("keys: " + Object.keys(date).length);
            if(this.type === "single" && Object.keys(date).length > 2)
            {
                value = date.format("DD/MM/YYYY");
            }
            else if(this.type === "range" && Object.keys(date).length === 2) {
                value = date.start.format("DD/MM/YYYY") + " - " + date.end.format("DD/MM/YYYY");
            }

        }

        let placeholder = "DD/MM/YYYY";
        if (this.type === "range") {
            placeholder = "DD/MM/YYYY - DD/MM/YYYY";
        }

        let error = false;
        if(formInfoStorage.errors[this.name]) {
            error = formInfoStorage.errors[this.name].status;
        }


        return (
            <div style={{width:'260px'}}>
                <div style={{width: '100%'}}>
                    <Select
                        className={error ? "select select--error" : "select"}
                        classNamePrefix={"select"}
                        defaultMenuIsOpen={false}

                        placeholder={placeholder}

                        components={ { DropdownIndicator, Menu: this.Menu} }
                        isSearchable={false}

                        closeMenuOnSelect={false}

                        value = {value ? { value: date, label: value } : null}
                    />
                </div>
                <Error name={this.name} />
            </div>
        );
    }

}