import React, {Component} from "react";
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";
import "../../css/error.css";


export default @observer class Error extends Component {
    name = "";

    constructor(props) {
        super(props);
        this.name = props.name;
    }

    render() {
        const show = formInfoStorage.errors[this.name] && formInfoStorage.errors[this.name].status;

        const content = (
            <div className={"error__error_container"} style={{height: '100%'}}>
                <h1 className={"error__error_message"}>{show ? formInfoStorage.form_info.errors[this.name].message : null}</h1>
            </div>
        );

        return (
            <div style={{height: '20px'}}>
                {show ? content : ""}
            </div>
        )
    }
}