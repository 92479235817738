import React from 'react';

import './textbox.css';
import formInfoStorage from "../../../FormInfoStorage";

const defaultClassName = 'textbox';

function autoExpand(e) {
    const target = e.target;

    target.style.height = 'inherit';

    let computed = window.getComputedStyle(target);

    let height = target.scrollHeight
        + parseInt(computed.getPropertyValue('border-top-width'), 10)
        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    console.log("Scroll height: " + target.scrollHeight);

    target.style.height = height + 'px';
}

export function Textarea(props) {

    const className = props.className ? props.className : defaultClassName;
    const placeholder = props.placeholder;
    const name = props.name;

    return (
        <textarea
            className={className}
            onInput={autoExpand}
            placeholder={placeholder ? placeholder : "Put your text in me..."}
            defaultValue={formInfoStorage.form_info.values[name]}
            onChange={(event) => formInfoStorage.setInfo(name, event.target.value)}
            name={name}
        />
    )
}


