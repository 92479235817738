import React, {Component} from "react";

import StepperWidget from "../StepperWidget/StepperWidget";
import formInfoStorage from "../../FormInfoStorage";
import NextButton from "../Buttons/NextButton";


import "../../css/global.css";
import "../../css/bottom.css";
import BackButton from "../Buttons/BackButton";

export default class BottomSection extends Component {

    componentDidMount() {
        //adding shadow on scroll for bottom section
        window.addEventListener('scroll', () => {
            const isScrolled = (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
            const nav = document.querySelector(".global-bottom");
            if(!isScrolled) {
                nav.classList.add("global-bottom_shadow");
            }
            else {
                nav.classList.remove("global-bottom_shadow");
            }
        });

        //making the above work when resize
        window.addEventListener('resize', () => {
            const isScrolled = (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
            const nav = document.querySelector(".global-bottom");
            if(!isScrolled) {
                nav.classList.add("global-bottom_shadow");
            }
            else {
                nav.classList.remove("global-bottom_shadow");
            }
        });
    }


    render() {

        return (
            <div className={"global-bottom"}>
                <div className={"bottom__left"}>
                    {
                        (formInfoStorage.current_step !== 1 && formInfoStorage.current_step < formInfoStorage.max_step-1)
                            ? <BackButton />
                            : ""
                    }
                </div>
                <div className={"bottom__middle"}>
                    {
                        (formInfoStorage.current_step < formInfoStorage.max_step-1) ?
                            <StepperWidget/> : null
                    }

                </div>

                <div className={"bottom__right"}>
                    {
                        (formInfoStorage.current_step < formInfoStorage.max_step-1)
                            ? ( <NextButton />)
                            : ""
                    }
                </div>
            </div>
        );
    }
}