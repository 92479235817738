import React from "react";
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";
import ListItem from './ListItem';

export default @observer class List extends React.Component {

    newItem = () => {
        formInfoStorage.newItem(this.props.name);
    };

    render() {
        const name = this.props.name;

        return (
            <div className={"inventory-list"}>
                {formInfoStorage.inventory[name] ? formInfoStorage.inventory[name].map(item => {

                    return (
                        <ListItem item={item} list={name}/>
                    )
                }) : null}

                <div className={"inventory-list__bottom"} >
                    {
                        this.props.name ? (
                            <div className={"inventory-list__new-item-button"} onClick={this.newItem}>
                                <span style={{marginRight: '14px'}}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13 7H7V13H6V7H0V6H6V0H7V6H13V7Z" fill="#2678CB"/>
                                    </svg>
                                </span>
                                        <span>
                                    Add new item
                                </span>
                            </div>
                        ) : null
                    }

                </div>
            </div>
        );
    }
}