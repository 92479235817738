import React from 'react'
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";

const BigPlus = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0H7V7H0V8H7V15H8V8H15V7H8V0Z" fill="black"/>
    </svg>
);

const SmallPlus = () => (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 0H3.5V3.5H0V4.5H3.5V8H4.5V4.5H8V3.5H4.5V0Z" fill="black"/>
    </svg>
);

const SmallMinus = () => (
    <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.37121e-08 1.5L0 0.5L8 0.5V1.5L4.37121e-08 1.5Z" fill="black"/>
    </svg>

);

const DeleteIcon = () => (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 2H9V1C9 0.4 8.6 0 8 0H5C4.4 0 4 0.4 4 1V2H2H0V3H1L2 13C2 13.6 2.4 14 3 14H10C10.6 14 11 13.6 11 13L12 3H13V2H11ZM5 1H8V2H5V1ZM10 13H3L2 3H11L10 13Z" fill="black"/>
    </svg>
);

const EditIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5182 0.431804C10.9425 -0.143935 10.009 -0.143935 9.43327 0.431804L1.2826 8.58245L1.18142 8.68364L1.15037 8.82334L0.167522 13.2462L0 14L0.75385 13.8325L5.17667 12.8496L5.31637 12.8186L5.41756 12.7174L13.5682 4.56675C14.144 3.99101 14.144 3.05755 13.5682 2.48181L11.5182 0.431804ZM10.1283 1.12678C10.3202 0.934869 10.6313 0.934869 10.8232 1.12678L12.8732 3.17679C13.0652 3.36871 13.0652 3.67986 12.8732 3.87177L12.1958 4.54925L9.45077 1.80426L10.1283 1.12678ZM8.75579 2.49924L2.07877 9.17624L1.29449 12.7055L4.82377 11.9212L11.5008 5.24423L8.75579 2.49924Z" fill="black"/>
    </svg>
);

function clearSelection() {
    if(document.selection && document.selection.empty) {
        document.selection.empty();
    } else if(window.getSelection) {
        let sel = window.getSelection();
        sel.removeAllRanges();
    }
}


export default @observer class ItemToolbar extends React.Component {

    constructor(props) {
        super(props);

        if(this.props.item.count === null) {
            console.log("NULL");
        }


        this.state = {
            collapsed: true,
            item: this.props.item,
            enteringCount: false,
        };

        if(this.props.onEdit) {
            this.edit = this.props.onEdit;
        }
    }

    selectContent = (e) => {
        e.target.select();
    };

    edit = () => {};

    toggle = () => {

        const collapsed  =! this.state.collapsed;

        if(collapsed) {
            document.querySelector(".inventory-list__item")
        }

        this.setState(prevState => ({
            ...prevState,
            collapsed: !prevState.collapsed,
        }))
    };

    deleteItem = () => {
        formInfoStorage.deleteItem(this.props.list, this.props.item.name);
    };

    collapseOnClickOutside = (e) => {
        let collapse = true;

        let parent = e.target;
        if(parent && parent.className === "expanded-toolbar") {
            collapse = false;
        }
        while(parent && parent.className !== "expanded-toolbar") {
            parent = parent.parentNode;

            if(parent && parent.className === "expanded-toolbar") {
                collapse = false;
            }
        }

        if( collapse ) {
            this.setState(prevState => ({
                ...prevState,
                collapsed: true,
            }))
        }
    };

    increment = (e) => {
        clearSelection();

        let item = this.props.item;
        item.count++;

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                item: item.count,
            }
        }))
    };

    decrement = (e) => {
        clearSelection();

        let item = this.props.item;

        if(item.count > 1) {
            item.count--;

            this.setState(prevState => ({
                ...prevState,
                item: {
                    ...prevState.item,
                    item: item.count,
                }
            }))
        }
        else {
            item.count = null;
        }

    };

    componentDidMount() {
        document.addEventListener('mousedown', this.collapseOnClickOutside);
        document.addEventListener('mousedown', this.saveCount);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.collapseOnClickOutside);
        document.removeEventListener('mousedown', this.saveCount);
    }

    enterCount = (e) => {
        this.setState(prevState => ({
            ...prevState,
            enteringCount: true,
        }))
    };

    saveCount = (e) => {
        this.setState(prevState => ({
            ...prevState,
            enteringCount: false,
        }))
    };

    render() {
        let item = this.props.item;

        const OpenIcon = (this.props.openIcon ? this.props.openIcon : BigPlus);

        return (
            <div className={"item-toolbar" + (this.state.collapsed ? "" : " item-toolbar--expanded")}>
                {
                    this.state.collapsed ?
                        (
                            <div className={"item-toolbar__icon big-plus"} onClick={this.toggle}>
                                <OpenIcon/>
                            </div>
                        )
                        : (
                            <div className={"expanded-toolbar"}>
                                <div className={"item-toolbar__icon expanded-toolbar__additional-icon"}
                                     style={{marginRight: '5px'}}
                                     onClick={this.deleteItem}
                                >
                                    <DeleteIcon />
                                </div>

                                <div className={"item-toolbar__icon expanded-toolbar__additional-icon"}
                                     style={{marginRight:'20px'}}
                                     onClick={this.edit}
                                >
                                    <EditIcon />
                                </div>

                                <div className={"item-toolbar__icon expanded-toolbar__icon "} onClick={this.decrement}>
                                    <SmallMinus/>
                                </div>

                                {
                                    this.state.enteringCount
                                        ? (
                                            <input type={"number"} className={"count-input"} value={item.count}
                                                   autoFocus={true}
                                                   onFocus={this.selectContent}
                                                   onChange={(e) => {item.count = e.target.value}}
                                            />)

                                        : (<p onClick={this.enterCount}>{item.count ? item.count : 0}</p>)
                                }


                                <div className={"item-toolbar__icon expanded-toolbar__icon "} onClick={this.increment}>
                                    <SmallPlus/>
                                </div>
                            </div>
                        )
                }

            </div>
        )
    }
}