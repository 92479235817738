import React from "react";
import {observer} from "mobx-react";
import formInfoStorage from "../../FormInfoStorage";

import "../../css/radio.css";


export default @observer class Radio extends React.Component {
    name = "";
    value = "";
    text = "";

    constructor(props) {
        super(props);

        this.name = props.name;
        this.value = props.value;
        this.text = props.text;
    }

    select = (e) => {
        formInfoStorage.setInfo(this.name, this.value);
    };


    render() {

        const selected = formInfoStorage.values[this.name] === this.value;
        const containerClass = "radio__container " + (selected ? "radio__container_selected" : "");

        return (
            <div className={containerClass} onClick={this.select}>
                <div className={"radio__icon"}>
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24876 9.93192L13.2477 0.932998L12.5405 0.225891L4.24876 8.5177L1.16365 5.4326L0.456543 6.1397L4.24876 9.93192Z" fill="white"/>
                    </svg>
                </div>
                <p className={"radio__text"}>
                    {this.text}
                </p>
            </div>
        );
    }
}