import React, {Component} from 'react';
import {observer} from "mobx-react";

import formInfoStorage from "../FormInfoStorage";

import '../css/location-group.css';
import '../css/forms.css';

import PlaceSelect from "./Form/PlaceSelect";
import SelectObserver from "./Form/SelectObserver";
import Checkbox from "./Form/Checkbox";


export default @observer class LocationFormGroup extends Component {
    prefix = this.props.prefix;

    render() {
        const prefix = this.prefix;

        const showPropertyType = formInfoStorage.form_info.values[prefix] !== null;

        let place_label = (prefix === "from" ? "Pick Up Location" : "Drop Off Location");
        if(formInfoStorage.values.moving_type === "office_removal") {
            place_label = (prefix === "from" ? "Office A" : "Office B");
        }
        else if(formInfoStorage.values.moving_type === "house_clearance" || formInfoStorage.values.moving_type === "furniture_disposal") {
            place_label = "Location";
        }

        let property_type_options = {
            "flat": "Flat",
            "house": "House",
            "other": "Other"
        };
        if(formInfoStorage.values.moving_type === "office_removal") {
            property_type_options = {
                "office_building": "Office building",
                "other": "Other",
            }
        }

        return (
            <div className={"location-group"}>
                <div className={"form__group"} style={{marginBottom: 0}}>
                    <p className={"form__label"}>{place_label}</p>
                    <PlaceSelect name={prefix} />
                </div>

                {
                    showPropertyType
                    ? (
                        <div className={"form__group"}  style={{marginBottom: 0}}>
                            <p className={"form__label"} style={{marginTop: '25px'}}>Property type</p>
                            <SelectObserver
                                options={property_type_options}
                                name={prefix + "_property_type"}

                                placeholder={"Select property type"}
                            />
                            <PropertyDetails prefix={this.prefix} />
                        </div>
                        ) : null
                }
            </div>

        );
    }
}

@observer class PropertyDetails extends Component {
    prefix = this.props.prefix;

    render() {


        const show = formInfoStorage.values[this.prefix + "_property_type"] !== null;
        const hideSelect = (formInfoStorage.values[this.prefix + "_property_type"] === "office_building"
            || (formInfoStorage.values[this.prefix + "_property_type"] !== "other" && formInfoStorage.values.moving_type === "office_removal"));

        const floor = formInfoStorage.form_info.values[this.prefix + "_floor"];
        const askForLift = floor !== "ground"
            && floor !== "1st"
            && floor !== null;


        let options = {};
        if(this.prefix === "to")
        {
            options = formInfoStorage.propertyTypeOptionsTo;
        }
        else if(this.prefix === "from") {
            options = formInfoStorage.propertyTypeOptionsFrom;
        }

        const floorLabel = formInfoStorage.values[this.prefix + "_property_type"] === "house" ? "How many floors" : "Floor";
        let floorOptions = {
            "ground": "Ground floor",
            "1st": "1st floor",
            "2nd": "2nd floor",
            "3rd": "3rd floor",
            "4th": "4th floor",
            "4+": "4+ floor",
        };
        if(formInfoStorage.values[this.prefix + "_property_type"] === "house") {
            floorOptions = {
                "1": "1 floor",
                "2": "2 floors",
                "3": "3 floors",
                "4": "4 floors",
                "4+": "4+ floors",
            }
        }

        return (
            <React.Fragment>
            {
                show
                ? (
                    <React.Fragment>
                        {
                            !hideSelect ? (
                                <SelectObserver
                                    name={this.prefix + "_property_type_detail"}
                                    options={options}
                                />
                            )  : null
                        }


                        <div className={"form__group"} style={{marginBottom: '25px'}}>
                            <p className={"form__label"} style={{marginTop: '25px'}}>{floorLabel}</p>
                            <SelectObserver
                                name={this.prefix + "_floor"}
                                options={floorOptions}

                                placeholder={"Select floor"}
                            />
                        </div>
                        {
                            askForLift ?
                                <div style={{marginBottom: '40px'}}>
                                    <Checkbox name={this.prefix + "_lift_available"} label={"Lift available"} />
                                </div> : null
                        }


                        <div className={"form__group"} style={{marginBottom: 0, width: '220px'}}>
                            <p className={"form__label"} style={ {whiteSpace: 'nowrap'}}>Walking distance to the loading point</p>
                            <SelectObserver
                                name={this.prefix + "_walking_distance"}
                                options={{
                                    "<5": "< 5m",
                                    "5-10": "5-10m",
                                    "10-20": '10-20m',
                                    "20-30": '20-30m',
                                    ">30": "> 30m"
                                }}
                                placeholder={"Select distance"}
                            />
                        </div>
                    </React.Fragment>

                ) : null
            }
            </React.Fragment>

        )
    }
}