import React from "react";

import Select, {components} from "react-select";
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";
import '../../css/date-picker/date-select.css';
import {toJS} from "mobx";
import {debounce} from 'lodash';

import '../../css/select.css';
import Error from "./Error";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M1.28033 0C1.42098 0 1.55587 0.0558739 1.65533 0.15533L4 2.5L6.34467 0.15533C6.44413 0.0558737 6.57902 0 6.71967 0C7.19214 0 7.42876 0.571241 7.09467 0.90533L4 4L0.905329 0.905329C0.57124 0.57124 0.807856 0 1.28033 0Z" fill="#0A0E12"/>
                </svg>
            </components.DropdownIndicator>
        )
    );
};

function convertPredictionsToOptions(predictions) {
    let options = [];

    predictions.map(prediction => {
       options.push({value: prediction, label: prediction.description});
    });

    return options;
}

export default @observer class PlaceSelect extends React.Component {
    name = this.props.name;
    closingMenu = false;

    constructor(props, context) {
        super(props, context);

        let value = null;
        if(formInfoStorage.values[this.name]) {
            value = toJS(formInfoStorage.values[this.name]);
        }

        let options = [];
        if (value) {
            options = [value];
        }

        this.state = {
            options: options
        };

        this.autocompleteService = new window.google.maps.places.AutocompleteService();
    }

    handlePredictions = (predictions, status) => {
        //turn predictions into options
        if(predictions) {
            let options = convertPredictionsToOptions(predictions);

            this.setState(prevState => ({
                ...prevState,
                options: options
            }))
        }
    };

    //called on each keystroke
    onChange = (value) => {
        console.log("VALUE: " + value);

        //react-select is implemented so onInputChange is firing with value="" when menu closes
        //we have to check this case so it doesn't reset the field
        if(!this.closingMenu) {
            formInfoStorage.setInfo(this.name, {label: value, value: {description: value}});
        } else {this.closingMenu = false}

        //prepare request
        const request = {
            input: value,
            componentRestrictions: {country: 'uk'},
        };
        //get predictions and set options

        this.autocompleteService.getPlacePredictions(request, this.handlePredictions);
    };

    makeRequest = debounce(value => {
        //prepare request
        const request = {
            input: value,
        };
        //get predictions and set options

        this.autocompleteService.getPlacePredictions(request, this.handlePredictions);
    }, 200);

    //called when user selects value from dropdown
    save = (value) => {
        console.log("SAVING VALUE: ");
        console.log(value);

        formInfoStorage.setInfo(this.name, value)
    };



    render() {
        console.log("PlaceSelect value: ");

        let value = null;
        if(formInfoStorage.values[this.name]) {
            value = toJS(formInfoStorage.values[this.name]);
        }

        let error = false;
        if(formInfoStorage.errors[this.name]) {
            error = formInfoStorage.errors[this.name].status;
        }
        console.log(value);

        const inputValue = value ? value.label : "";

        return (
            <React.Fragment>
                <Select
                    className={error ? "select select--error" : "select"}
                    classNamePrefix={"select"}

                    defaultMenuIsOpen={false}

                    onInputChange={debounce(this.onChange, 200)}
                    onMenuClose={() => {this.closingMenu = true}}

                    onChange={this.save}

                    options={this.state.options}

                    components={{DropdownIndicator}}

                    value={value}

                    placeholder={"Postcode or Town"}
                />
                <Error name={this.name} />
            </React.Fragment>
        )
    }
}