import React from "react";
import BottomSection from "./BottomSection";
import TopSection from "./TopSection";
import ContentSection from "./ContentSection";

import "../../css/global.css";
import "../../css/buttons.css";

export default (props) => {
    let Content = props.content;

    return (
        <div className={"global-container"} style={{width:'100%'}}>
            <div style={{height: '65px'}}>
                <TopSection />
            </div>
            <div className={"global-content"}>
                <ContentSection>
                    <Content />
                </ContentSection>
            </div>
            <BottomSection />
        </div>
)}