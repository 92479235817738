import React from "react";
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";
import "./inventory-widget.css";
import List from "./List";

export default @observer class InventoryWidget extends React.Component {

    constructor(props) {
        super(props);

        if(props.tabs) {
            const tabs = props.tabs;

            //create if not exist
            tabs.map(tab => {
                if(! formInfoStorage.inventory[tab.name] ) {
                    formInfoStorage.inventory[tab.name] = [];
                }

                return true;
            });

            this.state = {
                selectedTab: tabs[0]
            }
        }
    }

    selectTab = (tab) => {
        this.setState(prevState => ({
            ...prevState,
            selectedTab: tab,
        }))
    };

    render() {

        //names to items in formInfoStorage.inventory object
        const tabs = this.props.tabs;
        const moving_type = formInfoStorage.values.moving_type;

        return (
            <div className={"inventory"}>
                <div className={"inventory__tabs"}>
                    {tabs.map((tab, index) => {
                        const selected = this.state.selectedTab.name === tab.name;

                        return (
                            <div key={index} onClick={() => this.selectTab(tab)}
                                 className={"inventory-option "  + (selected ? "inventory-option--selected" : "")}
                            >
                                <Tab label={tab.label}  icon={tab.icon ? tab.icon : null}/>
                            </div>
                        )
                    })}
                </div>

                <div className={"inventory__list"}>
                    <List name={this.state.selectedTab.name} />
                </div>
            </div>

        );

    }
}


class Tab extends React.Component {
    render() {
        const Icon = this.props.icon;
        const selected = this.props.selected;

        return (
            <React.Fragment>
                <span className={"inventory-option__label "}>{this.props.label}</span>
                {
                    Icon ?
                        <span className={"inventory-option__icon"}>{<Icon />}</span> : null
                }
            </React.Fragment>
        )
    }
}