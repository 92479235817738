import React, {Component} from "react";
import "../../css/top.css";


export default class TopSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            const isScrolled = window.scrollY > 0;
            const nav = document.querySelector(".top__container");
            if(isScrolled) {
                nav.classList.add("top__shadow");
            }
            else {
                nav.classList.remove("top__shadow");
            }
        });
    }

    render() {
        return (
            <div className={"top__container"}>
                <div className={"top__left_container"}>
                    <div className={"top__icon_container"}>
                        <a href={"https://www.wemoveanything.com/"} className={"top__icon"}>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.9 5L6.2 0.7L5.5 0L0 5.5L5.5 11L6.2 10.3L1.9 6H12L12 5H1.9Z" fill="#ADBCCB"/>
                            </svg>
                        </a>
                    </div>

                    <div className={"top__left_text_container"}>
                        <span className={"large-text top__text"}>What are you moving?</span>
                        <span className={"small-text top__text"}>Back to site</span>
                    </div>
                </div>

                <div className={"top__right_container"}>
                    <span className={"large-text top__text top__text_grey"} style={{fontSize: '12px'}}>Prefer to get a quote over the phone?</span>
                    <div className={"top__phone_icon_container top__text_grey"}>
                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H7C7.53043 0 8.03914 0.210714 8.41421 0.585786C8.78929 0.960859 9 1.46957 9 2V13C9 13.5304 8.78929 14.0391 8.41421 14.4142C8.03914 14.7893 7.53043 15 7 15H2C1.46957 15 0.960859 14.7893 0.585786 14.4142C0.210714 14.0391 0 13.5304 0 13V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM2 1H7C7.26522 1 7.51957 1.10536 7.70711 1.29289C7.89464 1.48043 8 1.73478 8 2V13C8 13.2652 7.89464 13.5196 7.70711 13.7071C7.51957 13.8946 7.26522 14 7 14H2C1.73478 14 1.48043 13.8946 1.29289 13.7071C1.10536 13.5196 1 13.2652 1 13V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1ZM6 12V13H3V12" fill="black"/>
                        </svg>
                    </div>
                    <a href="tel:0161-773-3266"> <span className={"top__phone"}>0161 773 3266</span> </a>
                </div>
            </div>
        )
    }
}