import React, {Component} from 'react';
import MovingTypeSelect from "./Form/MovingTypeSelect";

import "../css/forms.css";
import "../css/steps/movingtypestep.css";
import SelectObserver from "./Form/SelectObserver";
import DatePicker from "./Form/DatePicker";
import formInfoStorage from "../FormInfoStorage";
import {observer} from "mobx-react";
import LocationFormGroup from "./LocationFormGroup";


const WhenLabel = () => (
    <div style={{marginBottom: '30px', display: 'flex', alignContent:'center'}}>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <rect x="0.5" y="3.5" width="29" height="23" rx="0.5" stroke="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M30 10H0V9H30V10Z" fill="black" fillOpacity="0.4"/>
                <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M11.5 9V16.5H18.5V9H19.5V16.5H30V17.5H19.5V26H18.5V17.5H11.5V26H10.5V17.5H0V16.5H10.5V9H11.5Z" fill="black"/>
            </g>
        </svg>
        <p style={{display: 'inline-block', fontSize: '20px', opacity: '1 !important', margin: '0 15px', lineHeight: '30px'}}>When?</p>
    </div>
);

const Separator = () => (
    <div className={"from-where-selects__separator"}>
        <svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M6 22.5C6 23.8807 4.88071 25 3.5 25C2.11929 25 1 23.8807 1 22.5C1 21.1193 2.11929 20 3.5 20C4.88071 20 6 21.1193 6 22.5ZM12.5333 23.4667C12.2388 23.4667 12 23.2279 12 22.9333C12 22.6388 12.2388 22.4 12.5333 22.4L15.4667 22.4C15.7612 22.4 16 22.6388 16 22.9333C16 23.2279 15.7612 23.4667 15.4667 23.4667L12.5333 23.4667ZM20.5333 23.4667C20.2388 23.4667 20 23.2279 20 22.9333C20 22.6388 20.2388 22.4 20.5333 22.4H23.4667C23.7612 22.4 24 22.6388 24 22.9333C24 23.2279 23.7612 23.4667 23.4667 23.4667H20.5333ZM28 22.9333C28 23.2279 28.2388 23.4667 28.5333 23.4667L31.4667 23.4667C31.7612 23.4667 32 23.2279 32 22.9333C32 22.6388 31.7612 22.4 31.4667 22.4L28.5333 22.4C28.2388 22.4 28 22.6388 28 22.9333ZM40.5 25C41.8807 25 43 23.8807 43 22.5C43 21.1193 41.8807 20 40.5 20C39.1193 20 38 21.1193 38 22.5C38 23.8807 39.1193 25 40.5 25Z" fill="#8B9AA9"/>
        </svg>
    </div>);

const WhereLabel = () => (
    <div style={{display: 'flex', alignContent:'center'}}>
        <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M21 10.814C21 12.0606 20.4064 13.829 19.3854 15.8869C18.3824 17.9087 17.035 20.0785 15.6727 22.0849C14.3128 24.0877 12.9509 25.9086 11.928 27.2299C11.5725 27.6892 11.2583 28.0875 11 28.4115C10.7417 28.0875 10.4275 27.6892 10.072 27.2299C9.0491 25.9086 7.68724 24.0877 6.3273 22.0849C4.96495 20.0785 3.61757 17.9087 2.61456 15.8869C1.59357 13.829 1 12.0606 1 10.814C1 5.40987 5.461 1 11 1C16.539 1 21 5.40987 21 10.814ZM22 10.814C22 15.9083 13.9965 26.2714 11.6433 29.2082C11.2377 29.7144 11 30 11 30C11 30 10.7623 29.7144 10.3567 29.2082C8.00346 26.2714 0 15.9083 0 10.814C0 4.84157 4.92487 0 11 0C17.0751 0 22 4.84157 22 10.814ZM14 10C14 11.6569 12.6569 13 11 13C9.34315 13 8 11.6569 8 10C8 8.34315 9.34315 7 11 7C12.6569 7 14 8.34315 14 10ZM15 10C15 12.2091 13.2091 14 11 14C8.79086 14 7 12.2091 7 10C7 7.79086 8.79086 6 11 6C13.2091 6 15 7.79086 15 10Z" fill="black"/>
        </svg>
        <p style={{display: 'inline-block', fontSize: '20px', opacity: '1 !important', margin: '0 15px', lineHeight: '30px'}}>From where?</p>
    </div>
);

const ArrowOption = (props) => {
    const label = props.label;

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <span>{label}</span>

            <span style={{display:'flex'}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9 12.1464C9 12.0527 9.03725 11.9628 9.10355 11.8964L11 10L9.10355 8.10355C9.03725 8.03725 9 7.94732 9 7.85355C9 7.53857 9.38083 7.38083 9.60355 7.60355L12 10L9.60355 12.3964C9.38083 12.6192 9 12.4614 9 12.1464Z" fill="#0A0E12"/>
                        </g>
                    </svg>
                </span>
        </div>
    );
};

export default @observer class MovingType extends Component {

    render() {
        const showSingleDatePicker = formInfoStorage.form_info.values.when_type === "fixed_date";
        const showRangeDatePicker = formInfoStorage.form_info.values.when_type === "between_dates";

        const showDestinationSection = !["house_clearance", "furniture_disposal", "man_power"].includes(formInfoStorage.values.moving_type);

        return (
          <div>
              <h1 className={"h1"}>What are you moving?</h1>
              <div className={"form__group"} style={{marginTop: '30px', marginBottom: 0}}>
                  <MovingTypeSelect />
              </div>

              <div className={"form__group when-group"} >
                  <WhenLabel/>
                  <div className={"when-selects"}>
                      <div className={"moving-type-select"}>
                          <SelectObserver
                              options={{
                                  "one_week": "Within 1 week",
                                  "fixed_date": <ArrowOption label={"On a fixed date"}/>,
                                  "urgently": "Urgently",
                                  "between_dates": <ArrowOption label="Between dates" />,
                                  "flexible": "I'm flexible",
                                  "no_date": "Don't have a date yet",
                              }}
                              name={"when_type"}

                              placeholder={"Select a date option"}
                          />
                      </div>

                      <div className={"datepicker-container"}>
                          {
                              showSingleDatePicker
                                  ? <DatePicker name={"when"} type={"single"} />
                                  : null
                          }

                          {
                              showRangeDatePicker
                                  ? <DatePicker name={"when"} type={"range"} />
                                  : null
                          }
                      </div>

                  </div>
              </div>

              <div className={"form__group"} style={{marginTop: '38px'}}>

                  <WhereLabel/>
                  <div className={"from-where-selects"}>
                      <LocationFormGroup prefix={"from"} />

                      {
                          (showDestinationSection)
                          ?
                              <React.Fragment>
                                  <Separator />
                                  <LocationFormGroup prefix={"to"} />
                              </React.Fragment> : null
                      }
                  </div>
              </div>
          </div>
        );
    }


}