import React from "react";
import Select, {components} from "react-select";
import {observer} from "mobx-react";
import Error from "./Error";
import formInfoStorage from "../../FormInfoStorage";

import "../../css/select.css";

export default @observer class SelectObserver extends React.Component {
    options = this.props.options;
    name = this.props.name;
    placeholder = null;

    touched = formInfoStorage.form_info.values[this.props.name] != null;

    styles = {
        container: (provided, state) => ({
            ...provided,
            outline: 'none',
            fontSize: '14px',
            boxSizing: 'border-box',
            height: '45px',

            backgroundColor: '#FFFFFF',

            borderRadius: '1px',
            border: state.isFocused
                ? '1px solid #2F80ED'
                : '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: state.isFocused
                ? '0px 15px 20px rgba(0, 0, 0, 0.05)'
                : '',
            ':hover': {
                border: '1px solid rgba(0, 0, 0, 0.2)',
            },
        }),
        control: (provided, state) => ({
            ...provided,
            height: '100%',
            width: '100%',
            padding: 0,


            borderRadius: '2px',

            border: state.isFocused
                ? 'none !important'
                : 'none !important',

            ':hover': {
                color: 'rgba(0,0,0,0.6) !important',
            },

        }),

        valueContainer: (styles, state) => ({
            ...styles,
            height: '100%',
            marginRight: '0',
            padding: 0,
            paddingLeft: '1rem',
            border: 'none',
            opacity: 1,

            boxSizing: 'border-box',

            borderRadius: '2px',
        }),

        input: (styles, state) => ({
            ...styles,
            border: 'none',
            margin: 0,
            paddingBottom: 0,
            paddingLeft: '2px',

            lineHeight: '19px',

            color: 'rgba(0,0,0,0.6)',
            opacity: 1,
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: 'none',
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            border: 'none',
            marginRight: '13px',
        }),
        placeholder: (styles, state) => ({
            ...styles,
            height: '100%',
            margin: '0',
            paddingLeft: '2px',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',

            left: '1rem',

            opacity: 1,
            color: 'rgba(0,0,0,0.55)',

            lineHeight: '19px',
            borderRadius: '2px',
            border: 'none',

        }),

        menu: (styles) => ({
            ...styles,
            background: '#FFFFFF',
            borderRadius: '3x',
            boxShadow: '0px 25px 30px rgba(0, 0, 0, 0.05)'
        }),

        option: (styles, state) => ({
           ...styles,
            background: '#FFFFFF',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0,0,0,0.6)',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.03)',
                color: 'rgba(0,0,0,1)',
            }
        }),

        singleValue: (styles, state) => ({
            ...styles,
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: state.isFocused
                ? 'rgba(0,0,0,0.8) !important'
                : 'rgba(0,0,0,1)',
            opacity: 1,

            'svg': {
                display: 'none',
            }
        }),

        dropdownIndicator: (styles, state) => ({
            ...styles,
        })
    };


    constructor(props) {
        super(props);

        if(props.placeholder) {
            this.placeholder = props.placeholder;
        }

        this.options = props.options;
    }

    menuOpen = () => {
            this.styles.dropdownIndicator = (styles, state) => ({
                ...styles,
                transform: 'rotate(180deg)',
                transition: 'transform 150ms linear',
            });

        this.render();
    };
    menuClose = () => {
        this.styles.dropdownIndicator = (styles, state) => ({
            ...styles,
            transition: 'transform 150ms linear',
            transform: 'rotate(0deg)',
        });


        this.render();
    };

    render() {
        const value = formInfoStorage.form_info.values[this.name];

        let className = "select";

        let defaultValue = value
            ? {
                value: value,
                label: this.props.options[value],
            }
            : null;

        if(!this.props.options[value]) {
            defaultValue = null;
        }

        //apply styles if error
        const isError = formInfoStorage.errors[this.name] && formInfoStorage.errors[this.name].status;
        if(isError) {
            this.styles.container = (provided, state) => ({
                ...provided,
                outline: 'none',
                fontSize: '14px',
                boxSizing: 'border-box',
                height: '45px',

                borderRadius: '2px',
                border: state.isFocused
                    ? '1px solid #2F80ED'
                    : '1px solid #EB5757',
                boxShadow: state.isFocused
                    ? '0px 15px 20px rgba(0, 0, 0, 0.05)'
                    : '0 15px 20px rgba(0, 0, 0, 0.05)',
            });

            className = "select select--error";
        }
        else {
            this.styles.container = (provided, state) => ({
                ...provided,
                outline: 'none',
                fontSize: '14px',
                boxSizing: 'border-box',
                height: '45px',

                borderRadius: '2px',
                border: state.isFocused
                    ? '1px solid #2F80ED'
                    : '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: state.isFocused
                    ? '0px 15px 20px rgba(0, 0, 0, 0.05)'
                    : '',
                ':hover': {
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                },
            });
        }

        //adapt options
        let adaptedOptions = [];
        if(this.props.options)
        {
            Object.keys(this.props.options).forEach( (name) => {
                adaptedOptions.push({
                    value: name,
                    label: this.props.options[name],
                })
            } );
        }

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M1.28033 0C1.42098 0 1.55587 0.0558739 1.65533 0.15533L4 2.5L6.34467 0.15533C6.44413 0.0558737 6.57902 0 6.71967 0C7.19214 0 7.42876 0.571241 7.09467 0.90533L4 4L0.905329 0.905329C0.57124 0.57124 0.807856 0 1.28033 0Z" fill="#0A0E12"/>
                        </svg>
                    </components.DropdownIndicator>
                )
            );
        };

        return (
            <React.Fragment>
                <Select
                        options={adaptedOptions}
                        className={"select"}
                        styles={this.styles}
                        classNamePrefix={"select"}
                        hideSelectedOptions={false}
                        placeholder={this.placeholder ? this.placeholder : "Select option..."}

                        components={ { DropdownIndicator } }

                        onBlur={() => {
                            formInfoStorage.touch(this.name)
                        }}
                        onChange={(option) => {
                            formInfoStorage.setInfo(this.name, option.value);

                            this.styles.singleValue = (styles, state) => ({
                                ...styles,
                                color: 'rgba(0,0,0,1)',
                            })
                        }}

                        onMenuOpen={this.menuOpen}
                        onMenuClose={this.menuClose}

                        defaultValue={defaultValue}
                        value={defaultValue}

                        isSearchable={false}
                />
                <Error name={this.name} />
            </React.Fragment>
        );
    }

}