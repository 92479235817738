import React from "react";
import formInfoStorage from "../FormInfoStorage";
import {observer} from "mobx-react";

import "../css/steps/inventorystep.css";
import InventoryWidget from "./InventoryWidget/InventoryWidget";

const bedrooms_icon = () => (
    <div>
        <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.24949H30V17.7495H29V8.24949H1V17.7495H0V7.24949Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.5 13.2495L0.5 13.2495L0.5 12.2495L29.5 12.2495V13.2495Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.4814 1.73202C17.8692 0.755994 12.1308 0.755994 6.51861 1.73202L4 2.17004V7.74949C4 8.02563 3.77614 8.24949 3.5 8.24949C3.22386 8.24949 3 8.02563 3 7.74949V1.74949C3 1.5064 3.17484 1.29853 3.41433 1.25688L6.34727 0.746807C12.0728 -0.248935 17.9272 -0.248936 23.6527 0.746806L26.5857 1.25688C26.8252 1.29853 27 1.5064 27 1.74949V7.74949C27 8.02563 26.7761 8.24949 26.5 8.24949C26.2239 8.24949 26 8.02563 26 7.74949V2.17004L23.4814 1.73202Z" fill="black"/>
            <rect opacity="0.3" x="14" y="1" width="1" height="7" fill="black"/>
        </svg>
    </div>
);

const living_icon = () => (
    <div>
        <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 8V12C5 13.1046 5.89543 14 7 14H23C24.1046 14 25 13.1046 25 12V8H24V12C24 12.5523 23.5523 13 23 13H7C6.44772 13 6 12.5523 6 12V8H5Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M25 9H29V19H1V9H5V8H0V20H30V8H25V9Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6 1H17.4C22.0392 1 25.8 4.13401 25.8 8H27C27 3.58172 22.7019 0 17.4 0H12.6C7.29807 0 3 3.58172 3 8H4.2C4.2 4.13401 7.96081 1 12.6 1Z" fill="black"/>
            <rect x="3" y="19" width="3" height="2" fill="#040404"/>
            <rect x="24" y="19" width="3" height="2" fill="#040404"/>
            <rect opacity="0.3" x="14" y="1" width="2" height="12" fill="black"/>
        </svg>
    </div>
);

const kitchen_icon = () => (
    <div>
        <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 18.5C18.0376 18.5 20.5 16.0376 20.5 13C20.5 9.96247 18.0376 7.50004 15 7.50004C11.9624 7.50004 9.5 9.96247 9.5 13C9.5 16.0376 11.9624 18.5 15 18.5ZM15 19.5C18.5899 19.5 21.5 16.5899 21.5 13C21.5 9.41019 18.5899 6.50004 15 6.50004C11.4101 6.50004 8.5 9.41019 8.5 13C8.5 16.5899 11.4101 19.5 15 19.5Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.3529 0.0221615C24.5627 -0.042405 24.79 0.0373841 24.9135 0.218883C26.3056 2.2662 29 8.34539 29 16.6765H28C28 10.1928 26.3255 5.14176 25 2.40773V25.5H24V0.500038C24 0.280552 24.1431 0.0867279 24.3529 0.0221615Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.5 16H28.5V17H24.5V16Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 25.5V0.500038H3.5V25.5H2.5Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0.500038H1V9.00004H5V0.500038H6V10H0V0.500038Z" fill="black"/>
        </svg>
    </div>
);

const bathroom_icon = () => (
    <div>
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.4871 17C29.2271 22.0143 25.079 26 20 26H10C4.92101 26 0.772938 22.0143 0.512932 17H29.4871Z" stroke="black"/>
            <rect x="6" y="26.5" width="3" height="2" fill="#C4C4C4"/>
            <rect x="21" y="26.5" width="3" height="2" fill="black"/>
            <rect x="6" y="26.5" width="3" height="2" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20 1.5C18.3431 1.5 17 2.84315 17 4.5H16C16 2.29086 17.7909 0.5 20 0.5H22.7322C23.9967 0.5 25.2094 1.00232 26.1036 1.89645C26.9977 2.79058 27.5 4.00328 27.5 5.26777V17H26.5V5.26777C26.5 4.26849 26.103 3.31015 25.3964 2.60355C24.6899 1.89696 23.7315 1.5 22.7322 1.5H20Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.584 13.2226L14.584 7.22265L15.416 7.77735L11.416 13.7773L10.584 13.2226Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.584 13.7773L17.584 7.77735L18.416 7.22265L22.416 13.2226L21.584 13.7773Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16 14.5V7.5H17V14.5H16Z" fill="black"/>
        </svg>

    </div>
);

const boxes_icon = () => (
    <div>
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="8.5" width="14" height="17" stroke="black"/>
            <rect x="9.5" y="0.5" width="8" height="8" stroke="black"/>
            <path d="M14.5 18.5H26.5V25.5H14.5V18.5Z" stroke="black"/>
        </svg>
    </div>
);

export default @observer class InventoryStep extends React.Component {


    render() {
        const moving_type = formInfoStorage.values.moving_type;

        let tabs;
        if(moving_type && moving_type === "office_removal") {
            tabs = [
                {label: "Office inventory", icon: null, name: "office"}
            ];
        }
        else {
            tabs = [
                {label: "Living", icon: living_icon, name: "living"},
                {label: "Bedrooms", icon: bedrooms_icon, name: "bedrooms"},
                {label: "Kitchen", icon: kitchen_icon, name: "kitchen"},
                {label: "Bathroom", icon: bathroom_icon, name: "bathroom"},
                {label: "Boxes & packaging", icon: boxes_icon, name: "boxes"},
                // {label: "Garden & shed", icon: bedrooms_icon, name: "garden"}
            ];
        }


        return (
            <div style={{paddingBottom: '1rem'}}>
                <h1 className={"inventory-step__header"} style={{marginBottom: '30px'}}>
                    Select your items
                </h1>

                <InventoryWidget tabs={tabs}/>
            </div>
        );
    }
}