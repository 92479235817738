import React, {Component} from 'react';
import formInfoStorage from './FormInfoStorage';
import Layout from "./components/Layout/GlobalLayout";
import {inject, observer} from "mobx-react";

import './css/global.css';

@inject('FormInfoStorage')
@observer
class App extends Component {
    steps = formInfoStorage.steps;

    render() {
        const step = formInfoStorage.current_step;

        let Content = this.steps[step];

        return (
            <React.Fragment>
                <Layout
                    content={Content}
                />
            </React.Fragment>
        );
    }
}

export default App;

