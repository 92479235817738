import React from "react";
import {observer} from "mobx-react";

import "../../css/checkbox.css";
import formInfoStorage from "../../FormInfoStorage";

const CheckMarkIcon = () => (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 8L0 5L1 4L3 6L9 0L10 1L3 8Z" fill="#2678CB"/>
    </svg>
);

export default @observer class Checkbox extends React.Component {
    label = this.props.label;
    name = this.props.name;

   constructor(props) {
       super(props);

       const checked = (formInfoStorage.values[props.name] ? formInfoStorage.values[props.name] : false);
       this.inverted = (props.inverted ? props.inverted : false);
   }



    handleChange = event => {
        console.log("Checkbox: " + event.target.checked);
        console.log("Checkbox: " + this.props.name);

        const checked = this.inverted ? !event.target.checked : event.target.checked;

        formInfoStorage.setInfo(this.props.name, checked);
    };

    render() {
        const checked = this.inverted ? !formInfoStorage.values[this.props.name] : formInfoStorage.values[this.props.name];

        return (
            <div className={"checkbox" + (checked ? " checkbox--checked" : "")}>
                <label style={{width: this.props.width ? this.props.width : 'fit-content'}}>
                    <div className={"checkbox__container"}
                    >
                        <input type={"checkbox"} onChange={this.handleChange} checked={checked}/>
                        <div className={"checkbox__checkmark"}>
                            <CheckMarkIcon/>
                        </div>
                    </div>
                    <div className={"checkbox__label-container"}>
                        <p className={this.props.labelClass ? this.props.labelClass : "checkbox__label"}>{this.props.label}</p>
                    </div>
                </label>
            </div>
        )
    }
}