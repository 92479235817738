import React, {Component} from 'react';
import "../../css/buttons.css";
import formInfoStorage from "../../FormInfoStorage";
import {observer} from "mobx-react";

export default @observer class Button extends Component {


    render() {
        const isDisabled = ! formInfoStorage.isNextStepAllowed;

        const className = "button button__next " + (isDisabled ? "button__disabled" : "");

        return (
            <button onClick={formInfoStorage.nextStep} className={className}>
                Next
            </button>
        );

    }
}