import React, {Component} from "react";
import "../css/buttons.css";
import "../css/steps/summary.css";
import formInfoStorage from "../FormInfoStorage";
import SummaryList from "./InventoryWidget/SummaryList";
import {toJS} from "mobx";



export default class Summary extends Component {
    ref_num = null;

    sendEmail = () => {
        let url = 'https://us-central1-wemoveanything-quote.cloudfunctions.net/sendMail';
        const Http = new XMLHttpRequest();

        let adaptedValues = toJS(formInfoStorage.values);
        if(adaptedValues.when) {
            if(adaptedValues.when.start) {
                adaptedValues.when = formInfoStorage.values.when.start.format("DD MMM [(]dddd[)]")
                    + " - "
                    + formInfoStorage.values.when.end.format("DD MMM [(]dddd[)]");
            } else { adaptedValues.when = formInfoStorage.values.when.format("DD MMM [(]dddd[)]") }
        }

        Http.open("POST", url);
        Http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        Http.send(JSON.stringify({
            input: adaptedValues,
            inventory: toJS(formInfoStorage.inventory),
            reference: this.ref_num,
        }));

        Http.onreadystatechange = (e) => {
            console.log(Http.responseText);
            console.log(Http);

            if(Http.readyState === XMLHttpRequest.DONE && Http.status === 200) {
                formInfoStorage.nextStep();
            }
        }
    };

    submit = () => {
        //get reference number
        let url = 'https://us-central1-wemoveanything-quote.cloudfunctions.net/getReferenceNumber';

        const Http = new XMLHttpRequest();
        Http.open("GET", url);
        Http.send();

        Http.onreadystatechange = (e) => {
            if(Http.readyState === XMLHttpRequest.DONE && Http.status === 200) {
                this.ref_num = Http.responseText;

                formInfoStorage.ref_number = Http.responseText;

                this.sendEmail();
            }
        };
    };

    render() {
        //construct array of items to display
        const lists = Object.keys(toJS(formInfoStorage.inventory));
        let items = [];
        let length = 0;
        if(lists) {
            for (const list of lists) {
                const list_items = formInfoStorage.inventory[list];

                for(const item of list_items) {
                    if(item.count) {
                        items.push({item: item, list: list});
                        length = length + item.count;
                    }
                }
            }
        }

        //construct header for list
        const labels = {
            "home_removal" : "Home Removal",
            "few_items": "A few items",
            "single_item": "Single item",
            "office_removal": "Office Removal",
            "furniture_disposal": "Furniture Disposal",
            "house_clearance": "House Clearance",
            "man_power": "Man power only",
        };

        const header = (
            <div className={"summary__inventory-header"}>
                <p>Inventory for {labels[formInfoStorage.values.moving_type]}
                <span style={{whiteSpace: "nowrap"}}>({length} {length === 1 ? "item" : "items"})</span>
                </p>
            </div>
        );

        return (
            <div className={"summary__container"}>
                <div className={"summary__inventory"}>
                    <SummaryList header={header} items={items}/>
                </div>
                <div className={"summary__buttons"}>
                    <button className={"button button__next button__next_summary"} style={{width:'100%', marginTop:'2rem'}} onClick={this.submit}>
                        Submit
                    </button>

                    <button className={"button button__back"} style={{width: '100%', marginTop:'23px'}} onClick={formInfoStorage.prevStep}>
                        Back to edit
                    </button>
                </div>
                <div className={"summary__details"}>
                    <Details />
                </div>
            </div>
        );
    }
}


class Details extends React.Component {


    constructDetails = (prefix) => {
        console.log("constructing details: " + prefix);

        let details = "";

        const property_type = formInfoStorage.values[prefix + "_property_type"];
        const property_type_detail = formInfoStorage.values[prefix + "_property_type_detail"];
        if(property_type) {
            if(property_type === "other") {
                details += property_type_detail + ", ";
            }
            else if(property_type !== "office_building"){
                details += property_type_detail + " " + property_type + ", ";
            }
            else {
                details += "office building, ";
            }
        }

        const floor = formInfoStorage.values[prefix + "_floor"];
        if(floor) {
            details += floor;

            if(formInfoStorage.values[prefix + "_property_type"] === "house" && floor !== "1") {
                details += " floors"
            }
            else {
                details += " floor"
            }
        }

        const lift = formInfoStorage.values[prefix + "_lift_available"];
        if(lift) {
            details += ", lift available"
        }
        else if(floor !== "ground" && floor !== "1st") {
            details += ", lift unavailable"
        }

        const walking_distance = formInfoStorage.values[prefix + "_walking_distance"];
        if(walking_distance) {
            details += ", walking distance " + walking_distance + "m";
        }

        return details;
    };


    render() {
        const showToLocation = !["house_clearance", "furniture_disposal", "man_power"].includes(formInfoStorage.values.moving_type);

        //compose date
        let date = null;
        switch (formInfoStorage.values.when_type) {
            case "between_dates": {
                date = formInfoStorage.values.when.start.format("DD MMM [(]dddd[)]")
                    + " - "
                    + formInfoStorage.values.when.end.format("DD MMM [(]dddd[)]");
                break;
            }
            case "fixed_date": {
                date = formInfoStorage.values.when.format("DD MMM [(]dddd[)]");
                break;
            }
            default: date = null;
        }


        let from = {
            label: "Location not selected"
        };
        if(formInfoStorage.values.from) {
            from = formInfoStorage.values.from;
        }
        let to = {
            label: "Location not selected"
        };
        if(formInfoStorage.values.to) {
            to = formInfoStorage.values.to;
        }

        const from_details = this.constructDetails("from");
        const to_details = this.constructDetails("to");

        return (
            <React.Fragment>
                <div >
                    {
                        date ? <React.Fragment>
                            <span className={"summary__label"} >Date</span>
                            {
                                <div className={"summary__date-display"}>
                                    <span className={"summary__date-value"}>{date}</span>
                                    <div className={"summary__icon_container"} onClick={() => formInfoStorage.goTo(2)}>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.5182 0.431804C10.9425 -0.143935 10.009 -0.143935 9.43327 0.431804L1.2826 8.58245L1.18142 8.68364L1.15037 8.82334L0.167522 13.2462L0 14L0.75385 13.8325L5.17667 12.8496L5.31637 12.8186L5.41756 12.7174L13.5682 4.56675C14.144 3.99101 14.144 3.05755 13.5682 2.48181L11.5182 0.431804ZM10.1283 1.12678C10.3202 0.934869 10.6313 0.934869 10.8232 1.12678L12.8732 3.17679C13.0652 3.36871 13.0652 3.67986 12.8732 3.87177L12.1958 4.54925L9.45077 1.80426L10.1283 1.12678ZM8.75579 2.49924L2.07877 9.17624L1.29449 12.7055L4.82377 11.9212L11.5008 5.24423L8.75579 2.49924Z" fill="#0A0E12"/>
                                        </svg>
                                    </div>
                                </div>
                            }
                        </React.Fragment> : null

                    }
                </div>

                <div style={{marginTop: '30px'}}>
                    <span className={"summary__label"}>Details</span>
                    <div style={{marginBottom: '10px'}}>
                        <span className={"summary__location"}>{from.label}</span>
                    </div>
                    <div className={"summary__property-details)"}>
                        <span>{from_details}</span>
                    </div>

                    {
                        showToLocation ?
                            <React.Fragment>
                                <div style={{marginTop: '15.5px', marginBottom: '15.5px'}}>
                                    <svg width="6" height="36" viewBox="0 0 6 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3.5 27.5C3.5 27.7761 3.27614 28 3 28C2.72386 28 2.5 27.7761 2.5 27.5L2.5 24.5C2.5 24.2239 2.72386 24 3 24C3.27614 24 3.5 24.2239 3.5 24.5L3.5 27.5ZM3.5 20.5C3.5 20.7761 3.27614 21 3 21C2.72386 21 2.5 20.7761 2.5 20.5L2.5 17.5C2.5 17.2239 2.72386 17 3 17C3.27614 17 3.5 17.2239 3.5 17.5L3.5 20.5ZM3 13.5C3.27614 13.5 3.5 13.2761 3.5 13L3.5 10C3.5 9.72386 3.27614 9.5 3 9.5C2.72386 9.5 2.5 9.72386 2.5 10L2.5 13C2.5 13.2761 2.72386 13.5 3 13.5Z" fill="black"/>
                                        <circle cx="3" cy="3" r="2.5" transform="rotate(90 3 3)" fill="#2678CB"/>
                                        <circle cx="3" cy="33" r="2.5" transform="rotate(90 3 33)" fill="#2678CB"/>
                                    </svg>
                                </div>
                                <div style={{marginBottom: '10px'}}>
                                    <span className={"summary__location"}>{to.label}</span>
                                </div>
                                <div className={"summary__property-details)"}>
                                    <span>{to_details}</span>
                                </div>
                            </React.Fragment> : null
                    }
                </div>
            </React.Fragment>
        )
    }
}